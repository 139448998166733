import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { ParameterAPI } from "@/api/parameter";
import store from "../index";
import { iParameterResponse } from "@/interfaces/parameter";

export interface iParameterStore {
  parameters: iParameterResponse[];
}

@Module({
  name: "ParameterStore",
  store,
  dynamic: true,
})
class ParameterStore extends VuexModule implements iParameterStore {
  parameters : iParameterResponse[]= [];
  private parameterAPI : ParameterAPI = new ParameterAPI();

  @Mutation
  SET_PARAMETERS(data: iParameterResponse[]) {
    this.parameters = data;
  }
  @Action
  async getParameters(){
    const data = await this.parameterAPI.getParameters();
    this.SET_PARAMETERS(data);
  }

}

export const ParamaterModule = getModule(ParameterStore);
