
import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ResizeMixin from "./mixin/ResizeMixin";

import NavigationDrawer from "../components/navigation/Drawer.vue";
import ToolBar from "../components/navigation/ToolBar.vue";
import {  MainModule } from "../store/modules/Main";
import { Route } from "vue-router";
import { getErrorCatch } from "../utils";
import { ParamaterModule } from "../store/modules/Parameter";
import { AuthModule } from "../store/modules/Auth";

@Component({
  name: "Layout",
  components: {
    NavigationDrawer,
    ToolBar
  }
})
export default class extends mixins(ResizeMixin) {
  get key() {
    return this.$route.path;
  }

  @Watch('$route')
  hanlderRoute({ query }: Route) {
    // eslint-disable-next-line 
    MainModule.setQueryParams(query as any)
  }

  get user(){
    return AuthModule.currentUser
  }

  async beforeMount() {
    try {
      await ParamaterModule.getParameters();
      MainModule.updateMenu(this.user);
    } catch (error) {
      getErrorCatch(error)
    }
  }
}
