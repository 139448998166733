
import { Component, Vue } from "vue-property-decorator";
import ItemMenu from "./ItemMenu.vue";
import { MainModule } from "@/store/modules/Main";

@Component({
  name: "MainMenu",
  components: {
    ItemMenu,
  },
})
export default class MainMenu extends Vue {
  get menuItems(){
    return MainModule.menu
  }
}
