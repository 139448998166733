
import { Component } from "vue-property-decorator";
import Vue from "vue";
import Notification from "./components/common/Notification.vue";

@Component({
  name: "App",
  components: {
    Notification,
  },
})
export default class App extends Vue {
}
