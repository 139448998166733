
import {Vue, Component} from 'vue-property-decorator'

@Component({
  name: 'ErrorPage'
})
export default class ErrorPage extends Vue{
  
}

