
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  name: "ModalDefault",
})
export default class ModalDefault extends Vue {
  @Prop({ type: Boolean, default: false, required: true }) isVisible!: boolean;
  @Prop({ type: String, default: '', required: true }) title!: string;
  @Prop({ type: Boolean, default: false , required: true}) loading!: string;
  @Prop({ type: String, default: '', required: false }) textButton!: string;
  @Prop({ type: String, default: 'Cerrar', required: false }) textButtonClose!: string;

  @Prop({ type: String, default: '#00204B', required: false }) overlayColor!: string;
  @Prop({ type: Number, default: .27, required: false }) overlayOpacity!: number;
  @Prop({ type: [String, Number], default: 600, required: false }) maxWidth!: boolean;
  @Prop({ type: Boolean, default: true, required: false }) isPersistent!: boolean;
  @Prop({ type: String, default: 'primary', required: false }) classButton!: string;
  @Prop({ type: Boolean, default: false, required: false }) withoutButtons!: boolean;
}
