import API from "@/utils/request";
import { AxiosInstance } from "axios";
import { ILogin } from "../interfaces/auth";
import { ROUTES_GLOBAL } from "./routes";

export class AuthApi {

  private request = new API(ROUTES_GLOBAL.AUTH);
  private api: AxiosInstance = this.request.getInstance();

  async login(dataLogin : ILogin){
    return await this.api.post('login',dataLogin)
  }
  async getProfile(){
    return await this.api.get('profile')
  }
}