import axios, { AxiosInstance } from "axios";
import { AuthModule } from "@/store/modules/Auth";
import { UrlConstants } from "../constants/url.constants";
import { MainModule } from "../store/modules/Main";

export class ErrorResponse {
  constructor(public message: string, public statusCode: number) {}
}

export default class API {
  private axios!: AxiosInstance;
  constructor(public path: string) {
    this.axios = axios.create({
      baseURL: UrlConstants.BASE_URL + path,
      timeout: 60000,
    });
    this.setInterceptorRequest();
    this.setInterceptorResponse();
  }

  private setInterceptorRequest() {
    this.axios.interceptors.request.use(
      (config) => {
        if (AuthModule.token) {
          if (config.headers) {
            config.headers["Authorization"] = AuthModule.token;
          }
        }
        if(MainModule.timeZone){
          if(config.headers){
            config.headers['timezone'] = MainModule.timeZone;
          }
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
  }
  private setInterceptorResponse(){
    this.axios.interceptors.response.use(
      function (response) {
        return response.data;
      },
      function (error) {
        const errorDescription = {
          message:
            error.response?.data?.message ||
            "Ocurrio un error, vuelva a intentarlo",
          statusCode: error.response?.data?.statusCode || 401,
        };
    
        if (
          errorDescription.message.length > 0 &&
          typeof errorDescription.message === "object"
        ) {
          errorDescription.message = `Tiene los siguientes errores: ${errorDescription.message.join(
            ","
          )}`;
        }
        const errorRequest = new ErrorResponse(errorDescription.message,errorDescription.statusCode)
        return Promise.reject(errorRequest);
      }
    );
  }

  public getInstance() : AxiosInstance{
    return this.axios
  }
}
