import Vue from "vue";
import { iCurrencyConfig, MainModule } from "../store/modules/Main";

export function formatCurrency(value: number) {
  const currency = MainModule.currency;

  return formatPrice(value, currency);
}

Vue.filter("formatCurrency", formatCurrency);

function formatPrice(price: number, currency: iCurrencyConfig) {
  try {
    const numberFormatted = numberFormat(
      price,
      currency.decimalDigits,
      currency.decimalSeparator,
      currency.thousandsSeparator
    );

    if (currency.currencySymbol) {
      const priceSeparator =
        currency.currencySymbolNumberOfSpaces > 0
          ? " ".repeat(currency.currencySymbolNumberOfSpaces)
          : "";
      let priceParts = [
        numberFormatted,
        priceSeparator,
        currency.currencySymbol,
      ];

      if (currency.currencySymbolPosition === "left") {
        priceParts = priceParts.reverse();
      }

      return priceParts.join("");
    } else {
      return numberFormatted;
    }
  } catch (e) {
    return price;
  }
}

function numberFormat(
  number: number,
  decimals: number,
  dec_point: string,
  thousands_sep: string
) {
  if (isNaN(number)) {
    return number;
  }

  const negative = number < 0;

  if (negative) number = number * -1;

  const str = number
    .toFixed(decimals ? decimals : 0)
    .toString()
    .split(".");
  const parts = [];

  for (let i = str[0].length; i > 0; i -= 3) {
    parts.unshift(str[0].substring(Math.max(0, i - 3), i));
  }

  str[0] = parts.join(thousands_sep ? thousands_sep : ",");
  if (str.length > 0) {
    const decimales = str[str.length - 1];
    if (decimales) {
      const toNumber = parseFloat(decimales);
      if (toNumber === 0) {
        if(str.length === 1 && str[0] === '0'){
          return "0"
        }
        str.pop();
        return (negative ? "-" : "") + str.join(dec_point ? dec_point : ".");
      }
    }
  }

  return (negative ? "-" : "") + str.join(dec_point ? dec_point : ".");
}
