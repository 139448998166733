import Vue from "vue";
import Vuex from "vuex";
import { iAuth } from "./modules/Auth";
import { iMain } from "./modules/Main";
import { config as vuexModuleDecoratorsConfig } from "vuex-module-decorators";
import { iTripStore } from "./modules/Trips";
import { iRouteStore } from "./modules/Route";
import { iVehicleStore } from "./modules/Vehicle";
import { iParameterStore } from "./modules/Parameter";
import { iDriversStore } from "./modules/Drivers";
import { iCustomerStore } from "./modules/Customers";
import { iCompanyStore } from "./modules/Company";
import { ISettlementStore } from "./modules/Settlements";
import { IBillingStore } from "./modules/Billing";
import { IModelsVehicleStore } from "./modules/Brands";
import { IAdminStore } from "./modules/Admins";
import { IProfileStore } from "./modules/Profile";
import { IProviderStore } from "./modules/Provider";

Vue.use(Vuex);

interface iRootStore {
  admin: IAdminStore;
  auth: iAuth;
  billing: IBillingStore;
  company: iCompanyStore;
  customers : iCustomerStore;
  drivers: iDriversStore;
  main: iMain;
  modelsVehicle: IModelsVehicleStore;
  parameter: iParameterStore;
  profile: IProfileStore;
  route: iRouteStore;
  settlement: ISettlementStore;
  trip: iTripStore;
  vehicle: iVehicleStore;
  provider : IProviderStore
}
vuexModuleDecoratorsConfig.rawError = true;

export default new Vuex.Store<iRootStore>({});
