

import { Component, Prop, Vue } from "vue-property-decorator";
import {iSubItemMenu} from '../../interfaces/menu'

@Component({
  name: 'NavMenu',
})
export default class NavMenu extends Vue{
  @Prop() readonly menuItem: iSubItemMenu | undefined;
}
