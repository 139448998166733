import Vue from 'vue'
/* eslint-disable */ 
Vue.prototype.$animate = function(node: HTMLElement, animationName: string, callback: Function){
  node.classList.add('animate__animated',`animate__${animationName}`);

  function handleAnimationEnd(){
    node.classList.remove('animate__animated',`animate__${animationName}`);
    node.removeEventListener('animationend',handleAnimationEnd);

    if(typeof callback === 'function') callback()
  }

  node.addEventListener('animationend',handleAnimationEnd);
}