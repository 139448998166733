export class UrlConstants {
  public static BASE_URL = process.env.VUE_APP_URL
    ? process.env.VUE_APP_URL
    : process.env.NODE_ENV === "production"
    ? "https://ptq.inparts.cl/api/"
    : "http://localhost:3000/api/";

  public static BASE_URL_PUBLIC = process.env.VUE_APP_URL_PUBLIC
    ? process.env.VUE_APP_URL_PUBLIC
    : process.env.NODE_ENV === "production"
    ? "https://ptq.inparts.cl/"
    : "http://localhost:3000/";
    
  public static STAGE = process.env.VUE_APP_STAGE ? process.env.VUE_APP_STAGE : 'development'
}
