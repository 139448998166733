import { AuthApi } from "../api/auth";
import { iProfileUserSession } from "../interfaces/users";
export class AuthService {
  private readonly authApi: AuthApi = new AuthApi();

  async getProfile(): Promise<iProfileUserSession | null> {
    const { data } = await this.authApi.getProfile();
    return data;
  }

  async login(username: string, password: string) {
    const { data } = await this.authApi.login({ username, password });
    return data;
  }
}
