
import {Component, Vue} from 'vue-property-decorator'
import { MainModule } from '../../store/modules/Main';

@Component({
  name: 'Notification'
})
export default class Notification extends Vue{

  get show(){
    return MainModule.notification.isOpen;
  }

  set show(value: boolean){
    MainModule.setNotification({message: '', isOpen: value, color : this.color})
  }

  get message(){
    return MainModule.notification.message
  }

  get color(){
    return MainModule.notification.color
  }

  get timeout(){
    return MainModule.notification.timeout
  }

  closeNotification(){
    MainModule.setNotification({ message: '', isOpen: false, color: this.color })
  }
}

