import { Route } from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { AuthModule } from "../store/modules/Auth";

import router from "../router";

NProgress.configure({ showSpinner: false });

const whiteList = ["/login", "/"];

/* eslint-disable */

router.beforeEach(async (to: Route, _: Route, next: any) => {
  NProgress.start();
  if (AuthModule.token) {
    if (AuthModule.isLogged) {
      next();
    } else {
      try {
        await AuthModule.getUser();
        next({ ...to, replace: true });
      } catch (error) {
        AuthModule.resetToken();
        next(`/login?redirect=${to.path}`);
        NProgress.done();
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next(`/login?redirect=${to.path}`);
      NProgress.done();
    }
  }
});

router.afterEach((to: Route) => {
  // Finish progress bar
  // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
  NProgress.done()
})
