import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { iProfileUserSession, IPermission } from "../../interfaces/users";
import { IPermissionGroupProfile } from "../../interfaces/profile";
import { resetRouter } from "../../router";
import { AuthService } from "../../services/auth";
import { getToken, removeToken, setToken } from "../../utils/cookies";
import store from "../index";

function getPermissionsGroup(profile: IPermission[]) {
  const profiles = profile.reduce<IPermissionGroupProfile[]>(
    (prev, current) => {
      const existPage = !!prev.find(
        (prevItem) => prevItem.page === current.page
      );
      if (existPage) {
        prev = prev.map((prevItem) => {
          if (prevItem.page === current.page) {
            prevItem.actions.push({ _id: current._id, action: current.action  });
          }
          return prevItem;
        });
      } else {
        prev.push({
          page: current.page,
          actions: [{ _id: current._id, action: current.action }],
        });
      }
      return prev;
    },
    []
  );
  return profiles || [];
}

export interface iAuth {
  currentUser: iProfileUserSession | null;
  isLogged: boolean;
  token: string;
  errorLogin: boolean;
}

@Module({
  name: "Auth",
  store,
  dynamic: true,
})
export class Auth extends VuexModule implements iAuth {
  currentUser: iProfileUserSession | null = null;
  isLogged = false;
  token: string = getToken() || "";
  errorLogin = false;

  private authService = new AuthService();

  @Mutation
  private SET_LOGGED(value: boolean) {
    this.isLogged = value;
  }

  @Mutation
  private SET_CURRENT_USER(value: iProfileUserSession | null) {
    this.currentUser = value;
  }

  @Mutation
  private SET_TOKEN(value: string) {
    this.token = value;
  }

  @Action
  async getUser() {
    if (this.token === "") {
      throw Error("token is undefined!");
    }
    const dataProfile = await this.authService.getProfile();
    if (!dataProfile) {
      throw Error("User not found");
    }
    if (!dataProfile.isSuper) {
      const permissions = getPermissionsGroup(
        dataProfile.profile.permissions as IPermission[]
      );
      dataProfile.profile.permissions = permissions;
    }
    this.SET_CURRENT_USER(dataProfile);
    this.SET_LOGGED(true);
  }

  @Action
  async login(data: { email: string; password: string }) {
    const response = await this.authService.login(data.email, data.password);
    if (!response.accessToken)
      throw new Error("Ocurrio un error, contacta con el administrador");
    const { accessToken } = response;
    const tokenBearer = `Bearer ${accessToken}`;
    setToken(tokenBearer);
    this.SET_TOKEN(tokenBearer);
  }

  @Action
  logout() {
    removeToken();
    resetRouter();
    this.SET_TOKEN("");
    this.SET_LOGGED(false);
    this.SET_CURRENT_USER(null);
    //this.authService.logout()
  }

  @Action
  resetToken() {
    removeToken();
    this.SET_TOKEN("");
    localStorage.removeItem("token");
  }
}

export const AuthModule = getModule(Auth);
