
import { Component, Vue } from "vue-property-decorator";
import { MainModule } from "../../store/modules/Main";
import ParameterModal from "./ParameterModal.vue";
import Profile from './Profile.vue'
import { UrlConstants } from '@/constants/url.constants'
import { DateTime } from "luxon";
@Component({
  name: 'ToolBar',
  components: {
    Profile,
    ParameterModal
  }
})
export default class ToolBar extends Vue {
  public visibleModalParameter = false;
  public urlConstants = UrlConstants;
  private timeout: number | undefined = undefined;
  public hour = '';
  toggleDrawer() {
    MainModule.toggleDrawer();
  }
  openParameters() {
    this.visibleModalParameter = true;
  }
  closeModalParameter() {
    this.visibleModalParameter = false
  }
  get timezone() {
    return MainModule.timeZone
  }

  beforeDestroy() {
    clearInterval(this.timeout);
  }

  mounted() {
    this.getDateCL()
    this.timeout = setInterval(() => {
    this.getDateCL()
    }, 10000);
  }

  getDateCL(){
    const dateCL = DateTime.now().setZone("America/Santiago");
    this.hour = dateCL.toFormat("yyyy-MM-dd HH:mm:ss");
  }
}
