
import {Component, Vue} from 'vue-property-decorator'
import avatar from '../../assets/images/avatar.svg'
import { AuthModule } from '../../store/modules/Auth'

@Component({
  name: 'ProfileMenu'
})
export default class ProfileMenu extends Vue{
  public profilePhoto : string = avatar
  
  get userDetails (){
    return AuthModule.currentUser
  }
}
