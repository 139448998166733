

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ParamaterModule } from "@/store/modules/Parameter";
import { getErrorCatch, validateNumber } from "@/utils";
import ModalDefault from "../common/ModalDefault.vue";
import { IRequestUpdateParameter } from "@/interfaces/parameter";
import { ParameterAPI } from "@/api/parameter";
import { MainModule } from "@/store/modules/Main";


@Component({
  name: 'ParameterModal',
  components: {
    ModalDefault
  }
})
export default class ParameterModal extends Vue {
  @Prop({ type: Boolean, default: false }) visible!: boolean;
  private parameterApi = new ParameterAPI();
  public visibleModalParameters = false;
  public loadingParameters = false;

  get parameters() {
    return ParamaterModule.parameters
  }

  public data = {
    fuelValue: '',
    tireValue: '',
    driverValue: '',
    commissionValue: '',
    vehicleMaintenanceValue: '',
    vehicleDepreciationValue: '',
    othersExpenses: ''
  }

  @Watch('parameters')
  private updateParameter() {
    this.data = {
      fuelValue: this.findParameter('fuel') as string,
      tireValue: this.findParameter('tire') as string,
      driverValue: this.findParameter('driver') as string,
      commissionValue: this.findParameter('commission') as string,
      vehicleMaintenanceValue: this.findParameter('vehicleMaintenance') as string,
      vehicleDepreciationValue: this.findParameter('vehicleDepreciation') as string,
      othersExpenses: this.findParameter('othersExpenses') as string,
    }
  }

  @Watch('visible')
  private handlerVisible(value: boolean) {
    this.visibleModalParameters = value;
  }

  findParameter(name: string) {
    return this.parameters.find((parameter) => parameter.name === name)?.value || ''
  }

  findIDParameter(name: string) {
    return this.parameters.find((parameter) => parameter.name === name)?._id || ''
  }

  closeModalParameter() {
    this.visibleModalParameters = false;
    this.$emit('closeModal')
  }

  async updateParameters() {
    const dataForm: IRequestUpdateParameter[] = [
      {
        id: this.findIDParameter('fuel'),
        value: parseFloat(this.data.fuelValue)
      },
      {
        id: this.findIDParameter('tire'),
        value: parseFloat(this.data.tireValue)
      },
      {
        id: this.findIDParameter('driver'),
        value: parseFloat(this.data.driverValue)
      },
      {
        id: this.findIDParameter('commission'),
        value: parseFloat(this.data.commissionValue)
      },
      {
        id: this.findIDParameter('vehicleMaintenance'),
        value: parseFloat(this.data.vehicleMaintenanceValue)
      },
      {
        id: this.findIDParameter('vehicleDepreciation'),
        value: parseFloat(this.data.vehicleDepreciationValue)
      },
      {
        id: this.findIDParameter('othersExpenses'),
        value: parseFloat(this.data.othersExpenses)
      },
    ];
    this.loadingParameters = true;
    try {
      await this.parameterApi.updateParameter(dataForm);
      MainModule.setNotification({
        isOpen: true,
        message: 'Parámetros actualizados',
        color: 'green darken-2'
      })
      await ParamaterModule.getParameters();
    } catch (error) {
      getErrorCatch(error)
    }
    this.loadingParameters = false;
  }

  openParameters() {
    this.visibleModalParameters = true;
  }
  validateNumber = validateNumber
}
