import API from "@/utils/request";
import { ROUTES_GLOBAL } from "../routes";
import { PARAMETERS_ENDPOINTS } from "./endpoints";
import { AxiosInstance } from "axios";
import {
  iParameterResponse,
  IRequestUpdateParameter,
} from "@/interfaces/parameter";

export class ParameterAPI {
  private request = new API(ROUTES_GLOBAL.PARAMETER);
  private api: AxiosInstance = this.request.getInstance();

  async getParameters() {
    const URL = `${PARAMETERS_ENDPOINTS.GET_PARAMETERS}`;
    const { data } = await this.api.get<iParameterResponse[]>(URL);
    return data;
  }
  async updateParameter(data: IRequestUpdateParameter[]) {
    await this.api.put(PARAMETERS_ENDPOINTS.UPDATE_PARAMETERS, {
      parameters: data,
    });
  }
}
