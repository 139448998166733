import nProgress from "nprogress";
import { NavigationGuardNext, Route } from "vue-router";
import { AuthModule } from "../store/modules/Auth";
export default function onlySuper(to: Route, from: Route, next: NavigationGuardNext) {
  nProgress.start();
  if(!AuthModule.currentUser){
    next('/login')
    nProgress.done();
  }
  const currentUser = AuthModule.currentUser;
  if(!currentUser?.isSuper){
    next('/dashboard')
    nProgress.done();
  }
  nProgress.done();
  next()
}
